<template>
  <div class="mtop-100">
    <b-container>
      <b-row>
        <b-col>
          <label>Month</label>
          <b-form-select v-model="month" :options="months"></b-form-select>
        </b-col>
        <b-col>
          <label>Classifications</label>
          <b-form-select v-model="sub_classification" :options="classifications"></b-form-select>
        </b-col>
      </b-row>
      <b-row v-if="loaded" class="mtop-100 mbottom-200 font-sfns">
        <b-col style="border: 2px solid #333;" class="m-4 p-4" cols="4" v-for="(category, index) in categories" :key="index">
         <div>
          <h4 class="font-sfns">{{ category }}</h4>
          <span class="d-block">Total Transactions: {{ monthlyComparison[category]["total_transactions"]}} </span>
          <span class="d-block">Sum of Transactions: {{ monthlyComparison[category]["sum_of_transactions"]}} </span>
          <span class="d-block">Change in No (%): {{ monthlyComparison[category]["percentage_of_change_in_no"]}} </span>
          <span class="d-block">Change in Vol (%): {{ monthlyComparison[category]["percentage_of_change_in_vol"]}} </span>
         </div>
        </b-col>
      </b-row>
     </b-container>
  </div>
</template>


<script>

export default {
  title: "Monthly Transactions",
  data() {
    return {
      loaded: false,
      month: 1,
      sub_classification: 1,
      months: [
        {
          value: 1,
          text: "January"
        },
        {
          value: 2,
          text: "February"
        },
        {
          value: 3,
          text: "March"
        },
        {
          value: 4,
          text: "April"
        },
        {
          value: 5,
          text: "May"
        },
        {
          value: 6,
          text: "June"
        },
        {
          value: 7,
          text: "July"
        },
        {
          value: 8,
          text: "August"
        },
        {
          value: 9,
          text: "September"
        },
        {
          value: 10,
          text: "October"
        },
        {
          value: 11,
          text: "November"
        },
        {
          value: 12,
          text: "December"
        }
      ]
    }
  },
  components: {
  },
  computed: {
    monthlyComparison() {
      return this.$store.state.transaction.monthly_comparison
    },
    classifications() {
      return this.$store.getters["transaction/listSubClassifications"]
    }
  },
  watch: {
    month() {
      if (this.month != "") {
        this.fetchMonthlyComparison()
      }
    },
    sub_classification() {
      if (this.sub_classification != "") {
        this.fetchMonthlyComparison()
      }
    }
  },
  methods: {
    fetchMonthlyComparison() {
      let loader = this.$loading.show({opacity: 0.9})
      this.$store.dispatch("transaction/fetchMonthlyComparison", {meta: {month: this.month, sub_classification: this.sub_classification}})
      .then(() => {
        this.loaded = true
        loader.hide()
      })
    }
  },
  created() {
    this.$store.dispatch("transaction/fetchClassifications", {meta: {page: 1, paginate: 200}})
    this.fetchMonthlyComparison()
  }

}
</script>

<style lang="scss">
</style>
